<template>
   <div class="tips bbox">
    <p class="title f14 fw700 textc">使用条款</p>
   <div class="f12">
      <p class="texti2">
        欢迎来到爱科来售后服务网站（以下简称"服务"或“网站”）。通过本服务，您可以访问各种资源和内容。其中包括：（a）网页、数据、消息、文本、图像、照片、图形、音频和视频以及文档（"材料"）：
        （b）公告栏和其他服务，您可以以浏览、下载的形式访问以下内容。（材料、公告以及其他信息、内容和服务统称为"内容"。）以下是您（"您"或"您的"）与爱科来国际贸易（上海）有限公司（以下简称"爱科来"）之间的法律协议条款。如果您打算继续浏览和使用本网站，表明您同意遵守以下使用条款。
        爱科来保留随时修改和实施这些条款的权利，而无需通知您。修订后的使用条款和隐私政策将在发布时生效。您可以在爱科来(arkray-service.com.cn)查看隐私政策中最新的使用条款。
      </p>
      <p class="f12 fw700 mt10">1. 不承诺声明</p>
      <p>
        本网站所载的文本、图片、照片、设计、数据、观点、建议、网页或链路（“资料和信息”）
        等仅供您参考。<br />
        爱科来不保证这些资料和信息的准确性、完整性、充分性、及时性和可靠性；不对这些资料和信息的偏差、错误或遗漏承担责任。对于您依赖本网站资料和信息所作出的任何决策,采取的任何行动，由您个人或您所代表的机构承担风险，爱科来不承担责任。爱科来保留随时对本网站上的资料和信息进行修改，更新及删除，而无需另行通知的权利。本
        网站保留的有与第三方网站或网址的链接仅为您提供方便，并不表示爱科来对该链接所显示的任何产品和服务的认可和推荐，也不用于宣传或广告目的。爱科来不保证这些链接上所提供的任何和资料和信息的准确性、完整性、充分性、及时性和可靠性。
      </p>
      <p class="f12 fw700 mt10">2. 适用于服务特定内容和领域的条款。</p>
      <p>
        通过服务或通过服务提供的某些服务或内容可能适用于您访问或使用该服务或内容领域的附加规则、准则、许可协议、用户协议或其他条款和条件（包括适用于公司或其他组织及其用户的条款和条件）。如果这些使用条款与服务特定领域的规则、准则、许可协议、用户协议或其他条款和条件之间存在冲突或不一致，则后者应优先于您访问和使用服务或内容的这一领域。
      </p>
      <p class="f12 fw700 mt10">3. 材料的使用</p>
      <p>
        您可以浏览,下载、存储在服务上发布的材料，内容如下：<br />
        （a）不得以任何方式修改或更 改材料：<br />
        （b）材料不得透露给任何第三方。
      </p>
      <p class="f12 fw700 mt10">4. 权利保留</p>
      <p>
        通过本服务提供的服务和内容是爱科来或第三方提供的知识产权和受版权保护的作品。保留未明确授予与服务或通过服务提供的服务和内容有关的所有权利、所有权和权益。
      </p>
      <p class="f12 fw700 mt10">5. 访问服务和内容的安全性、密码和手段</p>
      <p>
        您同意不以任何可能损坏、禁用、过载或损坏任何本网站帐户、计算机系统或网络的方式访问或使用服务。您同意不尝试未经授权访问服务的任何部分或任何本网站帐户、计算机系统或网络。您同意不干扰或试图干扰服务或任何本网站帐户、计算机系统或网络的正常工作。您同意在未经爱科来明确书面许可的情况下，不得使用任何机器人、间谍或其他自
        动手段访问服务或任何本网站帐户、计算机系统或网络。如果任何服务要求您开立账户，您必须按照适用的注册表的要求，向我们提供当前、完整和准确的信息，从而完成注册流程。您可能还需要选择密码和用户名。访问和使用受保护或保护的服务区域的密码仅限于授权用户。您同意不与任何其他人共享密码、帐户信息或访问服务。您负责维护密码和帐户信息的机密性，并且您负责通过使用密码或帐户或访问
        服务而发生的所有活动。您同意立即通知爱科来您未授权或未获得本使用条款授权的任何使用密码或帐户。
      </p>
      <p class="f12 fw700 mt10">6. 无非法或禁止使用</p>
      <p>
        您同意不将服务或通过服务提供的内容用于本使用条款非法或禁止的任何目的，或为服务或通过服务提供的特定领域或内容发布的规则、准则或使用条款。您完全有责任遵守服务适用国/地区的地方法律和法规。
      </p>
      <p class="f12 fw700 mt10">7. 赔偿</p>
      <p>
        您同意赔偿并持有无害的爱科来、其高管，董事、员工和代理人，以及因您共享的内容、违反本使用条款或为服务或通过服务提供的特定服务或内容而发布的任何附加规则、准则或使用条款而引起的任何索赔、责任、损害、损失或费用，包括合理的律师费用或者你侵犯或侵犯任何第三方权利，包括知识产权所产生的相关费用。
      </p>
      <p class="f12 fw700 mt10">8. 监测</p>
      <p>
        爱科来没有义务监控服务或通过服务共享内容。但是，爱科来保留审查服务和内容以及监控服务的所有使用和活动的权利，并自行决定删除或选择不通过服务提供任何内容。
      </p>

      <p class="f12 fw700 mt10">9. 终止使用</p>
      <p>
        爱科来可自行决定随时终止、停止提供或限制对服务的任何领域或通过服务提供的内容。您同意爱科来可随时自行决定终止或限制您访问或使用服务或任何内容。
      </p>
      <p class="f12 fw700 mt10">10. 免责声明</p>
      <p>
        爱科来明确声明不提供任何类型的所有保修，无论是明示还是暗示，包括但不限于商户的隐含担保、特定目的的适用性以及与服务及其通过所提供的所有内容无关的保证。爱科来 没有保证：<br>
        （A） 服务或内容将满足您的要求<br>
        （B）使用服务或通过服务提供的任何内容可能获得的结果将是准确或可靠的<br>
        （C）您通过或通过服务使用获得的任何内容的质量将满 足您的期望。通过使用本服务访问、下载或以其他方式获得的任何内容，请自行决定和风险使用。爱科来对下载或使用内容导致的计算机系统损坏或数据丢失不承担责任。爱科来保留随时对服务或通过本服务提供的服务和内容进行更改或更新以及监控其使用的权利，而无需通知。
      </p>
      <p class="f12 fw700 mt10">11. 责任限制</p>
      <p>
        在任何情况下，爱科来不得对您或任何第三方因您访问或使用所提供的服务或通过服务所提供的任何内容而引起的任何直接、间接、偶然、特殊或间接损害，或因您或任何第三方所遭受的利润、收入、数据或数据使用损失而承担任何损害责任。
      </p>
      <p class="f12 fw700 mt10">12. 排除和限制</p>
      <p>
        有些司法管辖区不允许免责声明或排除某些担保或某些责任的免责声明、排除或限制。如果这些使用条款中规定的免责声明、排除和限制（包括第 10 和第 12节所述的免责声明、排除和限制）在法律上无效，则不适用，所有其他条款应保持充分效力和效力。
      </p>
      <p class="f12 fw700 mt10">13. 知识产权</p>
      <p>
        a)本服务在服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归爱科来所有。 <br>
        b)除另有特别声明外，本服务提供服务时所依托软件的著作权、专利权及其他知识产权均归爱科来所有。 <br>
        c)本服务不保证为向用户提供便利而设置的外部链接的准确性和完整性，若由此而引起的版权问题或其他问题，请致电本服务客服。经核实后会立即予以删除或更改。<br>
        d)本服务页面所有信息受《中华人民共和国著作权法》及相关法律法规和中国加入的所有知识产权方面的国际条约的保护，未经爱科来或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品，因用户或其他第三人违反本法律声明而引发的任何一切索赔、损害等等， 爱科来概不负责。
      </p>
      <p class="f12 fw700 mt10">14. 版权/商标</p>
      <p>
        版权归 ARKRAY 和/或其附属公司（包括爱科来）所有。保留所有权利。方舟是ARKRAY 及其附属公司（包括爱科来）的注册商标。服务上出现的其他名称可能是其各自所有者的商标。未经许可复制、发行、修改、公开展示或公开展示受版权保护的作品，可能侵犯著作权人的权利。您同意，您不会使用服务或任何爱科来服务侵犯爱科来
        的知识产权或他人的知识产权。您不得从爱科来软件、第三方软件或您通过本服务服务访问的任何产品或内容的任何原件或副本中删除、污损或转载任何版权、商标、徽标或其他所有权通知。
      </p>
      <p class="f12 fw700 mt10">15. 隐私政策</p>
      <p>
        爱科来不会公开、编辑或透露用户保存在本服务中的个人信息，除非有法律许可要求或爱科来在诚信的基础上认为在以下情况下透露这些信息是必要的<br>
         a)事先获得用户的明确授权； <br>
         b)只有透露你的个人资料，才能提供你所要求的产品和服务； <br>
         c)根据有关的法律法规要求； <br>
         d) 按照相关政府主管部门的要求； <br>
         e)为维护本平台的合法权益。
      </p>
      <p class="f12 fw700 mt10">16. 未成年者</p>
      <p>
        未满多数年龄的人没有资格使用这项服务，我们要求不向我们提交与这类人有关的资料。
      </p>
      <p class="f12 fw700 mt10">17. 前瞻性和警告性陈述</p>
      <p>
        服务中列出的陈述可能构成适用法律意义上的前瞻性和警告性陈述。虽然这些前瞻性陈述
        代表了我们目前对未来前景的判断，但它们面临可能导致实际结果存在重大差异的风险和
        不确定性。我们提醒您不要过分依赖这些前瞻性陈述，这些陈述仅在服务中列出此类声明
        的日期就反映了我们的意见。请记住，我们没有义务根据新信息或未来事件修订或公开发
        布对这些前瞻性声明的任何修订结果。
      </p>
      <p class="f12 fw700 mt10">18. 豁免和可分离性</p>
      <p>
        爱科来未能行使或执行本使用条款中的任何权利或规定，不应构成对此类权利或条款的放
        弃。如果发现这些使用条款的任何部分或条款无法执行，则可以修改这些部分或条款，使
        《使用条款》成为可修改的合法和可执行条款。使用条款的余额不受影响。
      </p>
      <p class="f12 fw700 mt10">19. 适用法律/仲裁</p>
      <p>
        与您访问或使用服务或通过服务所提供的服务和内容有关的所有事项均受中国人民共和国
        法律管辖。当事人之间可能因使用、使用服务或内容而可能出现的所有争议、争议或分
        歧，
        当事人双方可以通过和解或者调解解决，当事人不愿和解、调解或者和解、调解不成
        的，采用向爱科来所在地人民法院起诉之方式解决。
      </p>
      <p class="f12 fw700 mt10">20. 连接服务条件</p>
      <p>
        您必须承担使用服务所需的费用。这包括使用 3G/4F
        蜂窝无线通信的情况。您同意爱科 来不应对任何损失或损坏负责。
      </p>
      <p class="f12 fw700 mt10">21. 系统维护</p>
      <p>爱科来可暂停服务以对系统进行维护 。</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.tips {
   background-color: #fff;
  padding: 20px 13px;
  
}
</style>